import React from "react";
import * as FaIcons from "react-icons/fa";

import DetailView from "../../parts/DetailView";
import Tabs from "../../components/Tabs";
import UnsavedChanges from "../../parts/UnsavedChanges";
import Additional from "../../parts/Additional";
import Meta from "../../parts/Meta";
import Button from "../../components/Button";
import Notes from "../../parts/Notes";

import Actions from "./Actions";
import Details from "./Details";
import View from "./View";

const TABS = [
  { key: 'tabDetails', label: 'Details' },
  { key: 'tabNotes', label: 'Notes' },
  { key: 'tabAdditional', label: 'Additional' },
];

const DocumentView = props => {
  const { endpoint } = props;
  const { apiId, iconName, localPath, title } = endpoint;

  const Icon = FaIcons[iconName];

  return (
    <DetailView
      {...props}
      apiId={apiId}
      listIcon={Icon}
      listLabel={title}
      listPath={localPath}
    >
      {p => <View {...p}/>}
    </DetailView>
  );
};

export default DocumentView;
