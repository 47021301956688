import React from "react";
import { FaPlus } from "react-icons/fa";
import { Search } from "@fabriqo/components";

import Button from "../../components/Button";

const Actions = props => {
  const {
    index,
    onClickAdd,
  } = props;

  return (
    <div className="flex mb-4">
      <div className="w-1/2 space-x-2">
        <Search index={index} />
      </div>
      <div className="w-1/2 flex justify-end">
        <Button onClick={onClickAdd}>
          <FaPlus />
        </Button>
      </div>
    </div>
  );
};

export default Actions;
