import { format } from "date-fns";
import React from "react";

const Meta = props => {
  const { block } = props;

  return (
    <>
      <h5 className="text-sm font-bold text-gray-700 mb-2">Meta</h5>
      <div className="mb-4">
        <div className="block text-gray-700 text-xs mb-2">
          Created At
          <p className="block pb-2 text-gray-700 leading-tight font-normal">
            {format(new Date(block._createdAt), "yyyy-MM-dd HH:mm")}
          </p>
        </div>
        
        <div className="block text-gray-700 text-xs mb-2">
          Updated At
          <p className="block pb-2 text-gray-700 leading-tight font-normal">
            {format(new Date(block._updatedAt), "yyyy-MM-dd HH:mm")}
          </p>
        </div>
      </div>
    </> 
  );
};

export default Meta;