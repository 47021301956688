import React, { useState } from "react";
import clsx from "clsx";

import HeaderBar from "./HeaderBar";

import "./index.css";

const withNavs = c => c;

const Header = props => {
  const {
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <header className={clsx("fixed left-0 right-0 flex flex-col pin-t pin-r pin-l text-depalmaWhite bg-depalmaBlack z-50", { "menu-is-open": menuIsOpen })}>
      <div className="">
        <HeaderBar
          menuIsOpen={menuIsOpen}
          setMenuIsOpen={setMenuIsOpen}
        />
        {/* <Menu isOpen={menuIsOpen} nav={headerNav} /> */}
      </div>
    </header>
  );
};

export default withNavs(Header);
