import React from "react";
import { useFormState } from "react-hook-form";
import Button from "../../components/Button";

const Actions = props => {
  const { onSubmit } = props;
  
  const { isDirty } = useFormState();

  return (
    <div className="flex items-center mb-4">
      <Button
        disabled={!isDirty}
        label={'Save'}
        onClick={onSubmit}
      />
    </div>
  );
};

export default Actions;
