import React from "react";
import { withAppLayout } from "../../parts/AppLayout";

const Dashboard = props => {

  return (
    <div className="">
      Dashboard
    </div>
  );
};

export default withAppLayout(Dashboard);
