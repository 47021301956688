import React, { useCallback, useMemo, useState } from "react";
import { format } from "date-fns";
import ReactMarkdown from "react-markdown";
import clsx from "clsx";
import { useApi } from "@fabriqo/api-rest";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import NoteForm from "./NoteForm";

const regexpIdentity = /^([^\<\[]+)?(?:\s{1})?(?:<(.+)>)?(?:\s{1})?(?:\[([0-9a-z-]+)\])/;

const components = {
  a({ node, inline, className, children, ...props }) {
    return (
      <a className={clsx("text-blue-500", className)} {...props}>
        {children}
      </a>
    );
  },
  ol({ node, inline, className, children, ordered, depth, ...props }) {
    return (
      <ol className={clsx("list-decimal ml-8", className)} {...props}>
        {children}
      </ol>
    );
  },
  ul({ node, inline, className, children, ordered, depth, ...props }) {
    return (
      <ul className={clsx("list-disc ml-8", className)} {...props}>
        {children}
      </ul>
    );
  },
};

const Note = props => {
  const { note, onDestroy, onUpdate } = props;
  const { _createdAt, _id, _writer, content } = note;

  const [_, name, email, id] = (_writer || "").match(regexpIdentity);
  const initials = (name || "?")
    .toUpperCase()
    .split(" ")
    .filter(Boolean)
    .slice(0, 2)
    .map(s => s[0])
    .join("");

  const createdAtDate = useMemo(() => _createdAt && new Date(_createdAt), [
    _createdAt,
  ]);

  const [editing, setEditing] = useState(false);

  const { destroy, update } = useApi("note");

  const handleClickDestroy = useCallback(async () => {
    if (confirm("Are you sure?")) {
      try {
        await destroy(_id);
        onDestroy && onDestroy(note);
      } catch (e) {
        console.log(e);
      }
    }
  }, [_id, destroy]);

  const handleClickEdit = useCallback(() => setEditing(true), []);

  const handleSave = useCallback(async data => {
    try {
      const { content } = data;
      const updBlock = await update(_id, { content });
      onUpdate && onUpdate(updBlock);
      setEditing(false);
    } catch(e) {
      console.log(e);
    }
  }, [_id, update]);

  return (
    <div className="flex mb-4 items-stretch">
      <div className="flex-initial w-8 flex flex-col">
        <span className="inline-flex h-6 w-6 mb-2 rounded-full justify-center leading-6 bg-depalmaBlue text-depalmaWhite text-sm items-center">
          {initials}
        </span>

        <button
          type="button"
          title="Edit"
          className="mb-1"
          onClick={handleClickEdit}
        >
          <FaPencilAlt />
        </button>

        <button
          type="button"
          title="Remove"
          className="text-red-400"
          onClick={handleClickDestroy}
        >
          <FaTrash />
        </button>
      </div>
      <div className="flex-1">
        <div className="text-gray-700 font-bold flex items-end">
          <span>
            {name}
            {createdAtDate ? (
              <span className="text-xs">
                {format(createdAtDate, "yyyy-MM-dd HH:mm")}
              </span>
            ) : null}
          </span>
        </div>

        {editing ? (
          <NoteForm
            block={note}
            onSubmit={handleSave}
          />
        ) : (
          <ReactMarkdown components={components}>{content}</ReactMarkdown>
        )}
      </div>
    </div>
  );
};

export default Note;
