import React from "react";
import { useForm } from "react-hook-form";
import Button from "../../components/Button";
import { renderField } from "../../components/Forms";

const field = { name: 'content', type: 'markdown', title: 'Content' };

const NoteForm = props => {
  const {
    block,
    onSubmit,
    resetFormRef,
  } = props;

  const { control, formState, handleSubmit, register, reset, watch } = useForm({ defaultValues: block, mode: 'onChange' });
  const { isDirty } = formState;
  if (resetFormRef) {
    resetFormRef.current = reset;
  }

  return (
    <div className="flex-1 flex flex-col h-full">
      <form onSubmit={handleSubmit(onSubmit)} className="flex-1 flex flex-col">
        {renderField({ field, control, formState, layout: 'no-label', register, watch })}

        <div>
          <Button
            disabled={!isDirty}
            label={'Save'}
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};

export default NoteForm;
