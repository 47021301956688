import React, { useCallback } from "react";

import { renderFieldContext } from "../../components/Forms";
import { filterAndSort } from "../../utils/blocks";

const summaryFieldNames = ["firstName", "lastName", "email", "phone"];

const Details = props => {
  const { block, fields, schema } = props;

  const summaryFields = filterAndSort(fields, summaryFieldNames);

  const renderField = useCallback(
    field =>
      renderFieldContext({
        field,
        layout: "horizontal",
      }),
    []
  );

  return (
    <div className="">
      <div className="mb-8">
        <h5 className="ml-40 mb-2 text-xs uppercase text-gray-600">Summary</h5>
        {summaryFields.map(renderField)}
      </div>
    </div>
  );
};

export default Details;
