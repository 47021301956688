import React from "react";
import { Controller } from "react-hook-form";

import { FieldWrapper, LabelWrapper } from "./Wrappers";

import EasyMDEReact from "../EasyMDEReact";

const FieldMarkdown = props => {
  const {
    attribute,
    control,
    description = null,
    errors = {},
    isDirty = false,
    label,
    layout = 'vertical',
    placeholder = label,
    register,
    ...rest
  } = props;

  const compProps = {};

  const renderControl = ({ field: { onChange, onBlur, value, name, ref }, fieldState, formState }) => (
    <EasyMDEReact
      onChange={onChange}
      value={value}
    />
  );

  return (
    <LabelWrapper label={label} layout={layout}>
      <FieldWrapper layout={layout}>
        <Controller
          {...rest}
          {...compProps}
          control={control}
          name={attribute}
          render={renderControl}
        />
      </FieldWrapper>
    </LabelWrapper>
  )
};

export default FieldMarkdown;
