import React, { useState } from "react";
import clsx from 'clsx';

const Tabs = (props) => {
  const {
    children,
    color = 'teal',
    wrapContent = false,
    tabs,
  } = props;

  const [openTab, setOpenTab] = useState(tabs[0].key);

  const renderTab = ({ label, key }) => (
    <li className="-mb-px mr-2 last:mr-0 flex-0 text-center" key={key}>
      <a
        key={key}
        className={clsx(
          "text-sm uppercase px-5 py-2 rounded block leading-normal hover:bg-gray-200",
          openTab === key ? `border-b-2 border-${color}-600 text-${color}-600` : null,
        )}
        onClick={e => {e.preventDefault(); setOpenTab(key);}}
        data-toggle="tab"
        href={`#${key}`}
        role="tablist"
      >
        {label}
      </a>
    </li>
  );
  return (
    <>
      <div className="flex-1 flex flex-col min-h-full">
        <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">{/*role="tablist" */}
          {tabs.map(renderTab)}
        </ul>
        <div className={clsx(
          "relative flex-1 flex flex-col min-w-0 break-words bg-white w-full",
        )}>
          <div className={clsx(
            "flex-auto pt-5 flex flex-col",
          )}>
            {children({ openTab })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;