import React, { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { FaArrowRight } from "react-icons/fa";
import Button from "../../components/Button";

function valueOr(val, placeholder = 'Blank') {
  if (typeof val === 'string' && val !== '') {
    return val;
  } else if (val && typeof val === 'object' && val._type === 'reference') {
    return <span className="italic font-normal text-gray-400 overflow-hidden">Ref: {val._ref}</span>;
  } else {
    return <span className="italic font-normal text-gray-400">{placeholder}</span>;
  }
}

const UnsavedChanges = props => {
  const { fieldDefinitions, block } = props;
  const { formState, reset, watch } = useFormContext();

  const renderDirtyField = useCallback(key => {
    const definition = fieldDefinitions[key];
    const title = definition ? definition.title || definition.name : key;

    return (
      <div key={key}>
        <div className="text-xs">{title}</div>
        <div className="flex items-center justify-stretch">
          <span className="flex-1 bg-gray-200 shadow rounded-sm px-2 overflow-hidden whitespace-nowrap">{valueOr(block[key])}</span>
          <FaArrowRight className="flex-0 mx-1" />
          <span className="flex-1 bg-gray-200 shadow rounded-sm px-2 overflow-hidden whitespace-nowrap">{valueOr(watch(key))}</span>
        </div>
      </div>
    );
  }, [fieldDefinitions, block, watch]);

  return (
    <div className="block text-gray-700 text-sm font-bold mb-2">
      {formState.isDirty ? 'Unsaved changes' : 'No unsaved changes'}
      <div className="block pb-2 text-gray-700 leading-tight font-normal">
        {Object.keys(formState.dirtyFields).map(renderDirtyField)}
      </div>

      <Button onClick={() => reset(block)} disabled={!formState.isDirty}>
        Reset
      </Button>
    </div>
  );
};

export default UnsavedChanges;
