import React, { Fragment, useCallback } from "react";
import { Router } from "@reach/router"

import PrivateRoute, { appConfig } from "../components/PrivateRoute";
import TwilioProvider from "../contexts/TwilioContext";
import { withLayout } from "../parts/Layout";

import Profile from "../pagesPrivate/Profile";
import CRM from "../pagesPrivate/CRM";
import Login from "../pagesPrivate/Login";
import OAuth from "../pagesPrivate/OAuth";
import Dashboard from "../pagesPrivate/Dashboard";
import IndividualsList from "../pagesPrivate/IndividualsList";
import IndividualView from "../pagesPrivate/IndividualView";
import LocationsList from "../pagesPrivate/LocationsList";
import LocationView from "../pagesPrivate/LocationView";
import MarketingProfilesList from "../pagesPrivate/MarketingProfilesList";
import MarketingProfileView from "../pagesPrivate/MarketingProfileView";
import OrganisationsList from "../pagesPrivate/OrganisationsList";
import OrganisationView from "../pagesPrivate/OrganisationView";

import DocumentList from "../pagesPrivate/DocumentList";
import DocumentView from "../pagesPrivate/DocumentView";
  
// markup
const AppPage = () => {
  const endpointRoute = useCallback((endpoint, i) => {
    const { localPath } = endpoint;
    const appPath = localPath.replace(/^\/app/, '');

    return (
      <Fragment key={i}>
        <PrivateRoute
          path={appPath}
          component={DocumentList}
          endpoint={endpoint}
        />
        <PrivateRoute
          path={`${appPath}/:id`}
          component={DocumentView}
          endpoint={endpoint}
        />
      </Fragment>
    );
  }, []);

  return (
    <TwilioProvider>
      <Router basepath="/app" className="flex-1 flex flex-col justify-stretch items-stretch">
        <PrivateRoute path="/" component={Dashboard} />
        <PrivateRoute path="/login" component={Login} />
        <PrivateRoute path="/oauth" component={OAuth} />
        <PrivateRoute path="/crm" component={CRM} />
        <PrivateRoute path="/crm/individuals" component={IndividualsList}/>
        <PrivateRoute path="/crm/individuals/:id" component={IndividualView}/>
        <PrivateRoute path="/crm/locations" component={LocationsList}/>
        <PrivateRoute path="/crm/locations/:id" component={LocationView}/>
        <PrivateRoute path="/crm/marketing_profiles" component={MarketingProfilesList}/>
        <PrivateRoute path="/crm/marketing_profiles/:id" component={MarketingProfileView}/>
        <PrivateRoute path="/crm/organisations" component={OrganisationsList}/>
        <PrivateRoute path="/crm/organisations/:id" component={OrganisationView} />
        <PrivateRoute path="/profile" component={Profile} />

        {appConfig.endpoints.map(endpointRoute)}
      </Router>
    </TwilioProvider>
  )
}

export default withLayout(AppPage);
