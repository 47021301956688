import React, { useCallback, useMemo, useState } from "react";
import axios from "axios";
import clsx from "clsx";

import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { useLoginContext } from "../../contexts/LoginContext";

const POSSIBLE_COUNTRY_CODES = ['NO', 'SE', 'DK'];

const SearchResult = props => {
  const { companyId, email, foundationYear, name } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className=""
    >
      <h5 className="text-xs" onClick={() => setExpanded(!expanded)}>
        {name}
        {' - Founded '}
        {foundationYear}
      </h5>
      <div className={clsx("mb-4", expanded ? 'block' : 'hidden')}>
        <p>{name}</p>
        <p>{email}</p>
        <textarea value={JSON.stringify(props)} />
      </div>
    </div>
  )
};

const SearchDialogue = props => {
  const {
    defaultCountryCode = 'SE',
    defaultQuery = '',
    onClose,
    onSubmit,
  } = props;

  const { getToken } = useLoginContext();

  const [query, setQuery] = useState(defaultQuery);
  const [countryCode, setCountryCode] = useState(defaultCountryCode);
  const [results, setResults] = useState([]);
  const [error, setError] = useState(null);
  const canSearch = useMemo(() => POSSIBLE_COUNTRY_CODES.indexOf(countryCode) !== -1, [countryCode]);

  const handleClickSearch = useCallback(async () => {
    if (!canSearch) return;

    try {
      setError(null);
      const { data } = await axios.get(`/.netlify/functions/proffCompanySearch`, {
        params: { countryCode, query },
        headers: { Authorization: `Bearer ${getToken()}` },
      });
      setResults(data.companies);
    } catch(e) {
      console.log(e);
      setError(e);
      setResults([]);
    }
  }, [canSearch, countryCode, query]);

  const handleChangeCountryCode = useCallback(e => {
    const { value } = e.target;
    setCountryCode(value.substr(0,2).toUpperCase());
  }, []);

  return (
    <Modal
      hasConfirm={false}
      hasClose={true}
      hideHeader={false}
      onClose={onClose}
      title={"Search Registry"}
    >
      <h5>Search either SE, NO or DK registry</h5>
      <div className="flex mb-8">
        <input
          className="w-12 block shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          onChange={handleChangeCountryCode}
          type="text"
          value={countryCode}
        />
        <input
          className="flex-1 block shadow appearance-none border rounded py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
          onChange={e => setQuery(e.target.value)}
          type="text"
          value={query}
        />
        <Button
          disabled={!canSearch}
          onClick={handleClickSearch}
        >
          Search
        </Button>
      </div>

      <div>
        {results.map((result, i) => (
          <SearchResult key={i} {...result} />
        ))}
      </div>
    </Modal>
  );
}

export default SearchDialogue;
