import React, { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import Modal from "../../components/Modal";
import { FaPhoneSlash, FaPhone, FaTimes } from "react-icons/fa";
import Button from "../../components/Button";
import { useTwilio } from "../../contexts/TwilioContext";

const validPhone = phoneNumber => {
  return typeof phoneNumber === 'string' && phoneNumber.length >= 10; // && phoneNumber.match(/^\+46/);
}

const phoneButtons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '+', '0', '#'];

const CallDialogueInner = props => {
  const {
    device,
    dial,
    endCall,
    log,
    phoneNumber,
    setIsOpen,
    setPhoneNumber,
  } = props;

  useEffect(() => {
    if (!device) {
      setIsOpen(false);
    }
  }, []);

  const handleClickCall = useCallback(() => {
    dial({ To: phoneNumber })
  }, [dial, phoneNumber]);

  if (!device) {
    return null;
  }

  const isActiveCall = useMemo(
    () => device._activeCall && device._activeCall._status !== 'closed',
    [device._activeCall, device._activeCall?._status],
  );

  const addDigit = useCallback(value => {
    if (!isActiveCall) {
      setPhoneNumber(pn => [pn, value].join(''));
    }
  }, [isActiveCall]);

  return (
    <Modal
      hasConfirm={false}
      hasClose={false}
      hideHeader={true}
      onClose={() => {}}
      title={"Caller"}
    >
      <div className="sm:flex">
        <div className="sm:w-1/2 sm:mr-1">
          <input
            className="block shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            disabled={isActiveCall}
            onChange={e => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
            type="text"
            value={phoneNumber}
          />
          <div className="w-48 mx-auto grid grid-cols-3 grid-rows-4">
            {phoneButtons.map((value, i) => (
              <div className="p-2" key={i}>
                <div
                  className="w-12 h-12 rounded-full bg-depalmaBlue text-depalmaWhite text-2xl flex items-center justify-center"
                  onClick={() => addDigit(value)}
                >
                  {value}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="sm:w-1/2 sm:ml-1">
          <h3>Call Log</h3>
          {log.map(([ts, msg], i) => (
            <div key={i} className="flex text-xs">
              <span className="w-16">{format(ts, "HH:mm:ss")}</span>
              <span>{msg}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center items-center pt-4 ml-1">
        <div className="flex">
          <Button
            className="text-xs averta-bold py-2 px-8 uppercase tracking-wider box-border focus:outline-none whitespace-nowrap rounded-full bg-green-500 disabled:bg-green-300 disabled:text-gray-500 mr-1"
            disabled={isActiveCall || !validPhone(phoneNumber)}
            onClick={handleClickCall}
            theme="none"
          >
            <FaPhone className="text-2xl"/>
          </Button>
          <Button
            className="text-xs averta-bold py-2 px-8 uppercase tracking-wider box-border focus:outline-none whitespace-nowrap rounded-full bg-red-500 disabled:bg-red-300 disabled:text-gray-500 ml-1"
            disabled={!isActiveCall}
            onClick={endCall}
            theme="none"
          >
            <FaPhoneSlash className="text-2xl"/>
          </Button>
        </div>

        <Button
          className="text-xs averta-bold py-2 px-2 mt-2 uppercase tracking-wider box-border focus:outline-none whitespace-nowrap rounded-full bg-depalmaBlue disabled:bg-blue-300 text-depalmaWhite disabled:text-gray-500 ml-1"
          // disabled={isActiveCall}
          onClick={() => setIsOpen(false)}
          theme="none"
        >
          <FaTimes className="text-2xl" />
        </Button>
      </div>
    </Modal>
  );
}

const CallDialogue = props => {
  const twilio = useTwilio();
  const { device, isOpen } = twilio;

  if (device && open) {
    return <CallDialogueInner {...props} {...twilio} />;
  }

  return null;
};

export default CallDialogue;
