import { Link } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";

import { useApi } from "@fabriqo/api-rest";
import Loading from "../../components/Loading";
import { ContentLayout } from "../../pagesPrivate/CRM";
import Form from "./Form";

const Breadcrumbs = props => {
  const {
    block,
    listIcon: Icon,
    listLabel,
    listPath,
  } = props;

  return (
    <h1 className="text-lg mb-8 flex">
      <Link className="mr-2" to={listPath}>
        {Icon ? <Icon className="mr-2 inline" /> : null}
        {listLabel}
      </Link>
      <span>
        {` > `}
        {block.name}
      </span>
    </h1>
  );
};

const DetailView = props => {
  const {
    apiId,
    children,
    confirmDestroyMessage = "Are you sure?",
    id,
    listIcon,
    listLabel,
    listPath,
  } = props;

  const { destroy, get, getSchema, getFields, update } = useApi(apiId);

  const [block, setBlock] = useState(null);
  const [schema, setSchema] = useState(null);
  const [fields, setFields] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fieldDefinitions = useMemo(() => {
    return fields ? fields.reduce((acc, fd) => ({ ...acc, [fd.name]: fd }), {}) : {};
  }, [fields]);

  useEffect(async () => {
    try {
      setLoading(true);
      await Promise.all([
        get(id).then(setBlock),
        getSchema().then(setSchema),
        getFields().then(setFields),
      ]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }, [id]);

  return (
    <ContentLayout>
      {loading || !block || !schema ? <Loading/> : (
        <div className="min-h-full flex flex-col flex-1">
          <Breadcrumbs
            block={block}
            listIcon={listIcon}
            listLabel={listLabel}
            listPath={listPath}
          />

          <Form {...{
            block,
            confirmDestroyMessage,
            destroy,
            fieldDefinitions,
            fields,
            id,
            listPath,
            schema,
            update,
          }}>
            {children}
          </Form>
        </div>
      )}
    </ContentLayout>
  );
};

export default DetailView;
