import { Link } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import { useApi } from "@fabriqo/api-rest";
import Loading from "../../components/Loading";

const LOCATION_FIELDS = {
  locationType: 'Location Type',
  "address.address": 'Address',
  "address.address2": 'Address 2',
  "address.city": 'City',
  "address.zip": 'Zip / Postal',
  "address.state": 'State',
  "address.country": 'Country',
};

const Locations = props => {
  const {
    active = false,
    block,
  } = props;
  const [loaded, setLoaded] = useState(false);
  const [locations, setLocations] = useState([]);

  const { index } = useApi('location');

  useEffect(() => {
    (async () => {
      if (active && !loaded) {
        const [data, error] = await index({ params: { query: { contact: { _ref: { $eq: block._id } } } } });
        if (!error) {
          const { records, noOfRecords } = data;
          setLocations(records);
        }
        setLoaded(true);
      }
    })();
  }, [active, loaded, block]);

  const renderLocation = useCallback((location, i) => {
    const { _id, name } = location;

    return (
      <div className="mb-8" key={i}>
        <Link to={`/app/crm/locations/${_id}`} className="ml-40 pl-3 text-blue-500">
          {name}
        </Link>
        
        {Object.keys(LOCATION_FIELDS).map(fieldName => renderLocationField(location, fieldName))}
      </div>
    );
  }, []);

  const renderLocationField = useCallback((location, fieldName) => {
    const value = fieldName.split('.').reduce((acc, f) => acc && acc[f], location);

    if (!value) return null;
    
    return (
      <div className="flex items-center">
        <div className="overflow-hidden overflow-ellipsis whitespace-nowrap text-gray-600 flex-0 w-40 text-xs text-right pr-2 uppercase font-normal">
          {LOCATION_FIELDS[fieldName]}
        </div>
        <div className="block flex-1 shadow appearance-none w-full py-1 px-3 leading-tight">
          {value}
        </div>
      </div>
    );
  }, []);

  return (
    <div className="flex-1 flex flex-col h-full">
      {loaded ? (
        <>
          {locations.map(renderLocation)}

          {!locations.length ? (
            <p className="">
              There are no locations associated with this organisation.
            </p>
          ) : null}
        </>
      ) : <Loading />}
    </div>
  );
};

export default Locations;
