import clsx from "clsx";
import React from "react";

const LabelWrapper = props => {
  const { children, label, layout = "vertical" } = props;

  if (layout === 'no-label')
    return children;

  return (
    <div className="mb-4">
      <label
        className={clsx("block text-sm", {
          block: layout === "vertical",
          "flex items-center": layout === "horizontal",
        })}
      >
        <span
          className={clsx("text-gray-600", {
            "flex-0 overflow-hidden overflow-ellipsis whitespace-nowrap w-40 text-xs text-right pr-2 uppercase font-normal":
              layout === "horizontal",
          })}
        >
          {label}
        </span>
        <div className={clsx({ "flex-1": layout === "horizontal" })}>
          {children}
        </div>
      </label>
    </div>
  );
};

const FieldWrapper = props => {
  const {
    children,
    layout = 'vertical',
    renderActions,
  } = props;

  return (
    <div className={clsx("flex items-center", { "flex-1": layout === 'horizontal' })}>
      <div className="flex-1">
        {children}
      </div>
      <div className="w-12 text-center">
        {renderActions ? renderActions() : null}
      </div>
    </div>
  );
};

export {
  FieldWrapper,
  LabelWrapper,
};
