import React from "react";
import { FormProvider, useForm } from "react-hook-form";

const Form = props => {
  const {
    block,
  } = props;

  const methods = useForm({ mode: "onChange", defaultValues: block });

  return (
    <FormProvider {...methods}>
      <h1>Profile</h1>
    </FormProvider>
  );
};

export default Form;
