import React from "react";
import { useForm } from "react-hook-form";
import { renderField } from "../../components/Forms";
import Modal from "../../components/Modal";

const AddDialogue = props => {
  const {
    defaultValues = null,
    onClose,
    onSubmit,
  } = props;

  const { control, handleSubmit, register, formState, reset, watch } = useForm({ mode: 'onChange', defaultValues });
  const field = { name: 'name', title: 'Name', type: 'string' };

  return (
    <Modal
      confirmLabel={'Add'}
      hasConfirm={true}
      hasClose={true}
      hideHeader={false}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      title={"Add Individual"}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderField({ field, control, formState, record: defaultValues || {}, register, watch })}
      </form>
    </Modal>
  );
}

export default AddDialogue;
