import React from "react";
import ContentLayout, { withContentLayout } from "./ContentLayout";

const CRM = props => {

  return (
    <div className="">
      CRM Dashboard
    </div>
  );
};

export default withContentLayout(CRM);

export {
  ContentLayout,
};
