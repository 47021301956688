import { useMemo } from "react";

export const filterAndSort = (fields, list) =>
  useMemo(
    () =>
    fields
        .filter(f => list.indexOf(f.name) !== -1)
        .sort(
          (a, b) => list.indexOf(a.name) - list.indexOf(b.name)
        ),
    [fields, list]
  );
