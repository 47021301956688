// import React, { useEffect, useState } from "react";
// import { navigate } from "gatsby";
// import { useIdentityStateContext } from "../../contexts/IdentityContext";
// import { FaLock, FaUnlock } from "react-icons/fa";
// import clsx from "clsx";
// import { getFabriqo } from "../../services/auth";

const OAuth = props => {
  // const [error, setError] = useState(null);
  // const [status, setStatus] = useState("waiting");
  // const { completeLogin } = useIdentityStateContext();

  // useEffect(async () => {
  //   try {
  //     const token = getFabriqo().parse(hash);
  //     await completeLogin(window.location.hash);
  //     setStatus("success");

  //     setTimeout(() => {
  //       token.metadata?.requestedRoute
  //         ? navigate(token.metadata.requestedRoute)
  //         : navigate("/app");
  //     }, 500);
  //   } catch (e) {
  //     setStatus("error");
  //     setError(e);
  //     setTimeout(() => navigate("/"), 500);
  //   }
  // }, []);

  // return (
  //   <div className="flex-1 flex flex-col min-h-screen bg-depalmaBlue text-depalmaWhite justify-between">
  //     <div className="flex-1"></div>
  //     <main className="mb-auto flex-0 text-center items-center">
  //       {status === "success" ? (
  //         <FaUnlock className="text-6xl animate-pulse text-green-500 mx-auto" />
  //       ) : (
  //         <FaLock className={clsx("text-6xl animate-pulse mx-auto", status === 'error' ? 'text-red-500' : 'text-yellow-500')} />
  //       )}
  //     </main>
  //     <div className="flex-1"></div>
  //   </div>
  // );
  return null;
};

export default OAuth;
