import React, { useCallback, useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { useApi, useListApi } from "@fabriqo/api-rest";
import {Table} from "@fabriqo/components";

import { ContentLayout } from "../CRM";
import LinkCellNavigate from "../DocumentList/LinkCellNavigate";

import Actions from "./Actions";
import AddDialogue from "./AddDialogue";

const tableComponents = {
  LinkCell: LinkCellNavigate,
};

const IndividualsList = props => {
  const {
    apiId = 'individual',
    children,
  } = props;

  const [addNew, setAddNew] = useState(null);

  const index = useListApi(apiId);
  const { create } = useApi(apiId);

  const { noOfRecords, load } = index;
  useEffect(() => { noOfRecords === 0 && load() }, []);

  const handleSubmitAdd = useCallback(async data => {
    try {
      const block = await create(data);
      setAddNew(null);
      navigate(`/app/crm/individuals/${block._id}`);
    } catch(e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <ContentLayout>
        <div className="flex items-start justify-between">
          <h1 className="text-lg mb-2">
            <FaUserAlt className="mr-2 inline" />
            Individuals
          </h1>
        </div>

        <Actions
          index={index}
          onClickAdd={() => setAddNew(true)}
        />

        <Table
          components={tableComponents}
          index={index}
          baseLinkURL={'/app/crm/individuals'}
        />

        {addNew ? (
          <AddDialogue
            onClose={() => setAddNew(null)}
            onSubmit={handleSubmitAdd}
          />
        ) : null}
      </ContentLayout>
    </>
  );
};

export default IndividualsList;
