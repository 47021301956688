import React from "react";
import { FaTrash } from "react-icons/fa";

import Tabs from "../../components/Tabs";
import UnsavedChanges from "../../parts/UnsavedChanges";
import Additional from "../../parts/Additional";
import Meta from "../../parts/Meta";
import Button from "../../components/Button";
import Notes from "../../parts/Notes";

import Actions from "./Actions";
import Details from "./Details";

const TABS = [
  { key: 'tabDetails', label: 'Details' },
  { key: 'tabNotes', label: 'Notes' },
  { key: 'tabAdditional', label: 'Additional' },
];

const View = props => {
  const {
    id,
    block,
    fieldDefinitions,
    fields,
    handleDestroy,
    onSubmit,
    schema,
  } = props;

  const tabProps = { fields, block, schema };

  return (
    <>
      <div className="flex items-start justify-between">
        <Actions onSubmit={onSubmit} />
      </div>

      <div className="flex flex-1">
        <div className="w-3/4 overflow-y-scroll">
          <Tabs tabs={TABS}>
            {({ openTab }) => (
              <>
                <div className={openTab === 'tabDetails' ? "block" : "hidden"} id="tabDetails">
                  <Details {...tabProps} active={openTab === 'tabDetails'} />
                </div>

                <div className={openTab === 'tabNotes' ? "min-h-full flex-1 flex flex-col" : "hidden"} id="tabNotes">
                  <Notes {...tabProps} active={openTab === 'tabNotes'} />
                </div>

                <div className={openTab === 'tabAdditional' ? "block" : "hidden"} id="tabAdditional">
                  <Additional {...tabProps} active={openTab === 'tabAdditional'} />
                </div>
              </>
            )}
          </Tabs>
        </div>

        <div className="w-1/4 pl-4 border-l ml-4">
          <Meta block={block} />

          <UnsavedChanges
            fieldDefinitions={fieldDefinitions}
            block={block}
          />
          
          <h5 className="text-sm font-bold text-gray-700 mb-2">Danger zone</h5>
          <div className="mb-4">
            <Button onClick={() => handleDestroy()} theme="danger">
              <FaTrash className="inline mr-1" />
              Delete this record
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default View;
