import React, { useCallback, useMemo } from "react";
import clsx from "clsx";
import { Link } from "gatsby";
import { FaPhone, FaUser } from "react-icons/fa";

import logoWorkwearWhite from "../../images/logo-depalma-workwear-white.svg";
import Button from "../../components/Button";
import { useTwilio } from "../../contexts/TwilioContext";

import AccountButton from "./AccountButton";
import CallDialogue from "./CallDialogue";

const HeaderBar = props => {
  const {
    menuIsOpen,
    setMenuIsOpen,
  } = props;

  const { device, connect, isOpen, setIsOpen, status } = useTwilio();

  const handleClickConnect = useCallback(async () => {
    connect().then(device => device && setIsOpen(true));
  }, [device, connect]);

  const [boxShadow, ledClassName] = useMemo(() => {
    if (status === 'connected') {
      return ["rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px", "bg-green-500"];
    } else if (status === 'connecting') {
      return ["rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #FF0 0 2px 12px", "bg-yellow-500"];
    }
    return ["rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px", "bg-red-500"];
  }, [status]);

  return (
    <nav className={clsx("px-4 flex items-center text-sm h-16")}>
      <div className="flex-initial text-xl -lg:hidden hidden">
        <button
          className={clsx("hamburger relative block focus:outline-none", {
            open: menuIsOpen,
          })}
          type="button"
          onClick={() => setMenuIsOpen(!menuIsOpen)}
        >
          <span className="hamburger__top-bun"></span>
          <span className="hamburger__bottom-bun"></span>
        </button>
      </div>

      <div className="-hidden -lg:flex flex items-center">
        <img
          src={logoWorkwearWhite}
          alt="DePalma Logo"
          className={clsx("h-8 lg:h-10 mr-8")}
        />

        <Link to="/app/crm" className="mr-8 flex items-center text-lg">
          <FaUser />
          <span className="ml-1 text-2xl">CRM</span>
        </Link>
      </div>

      <div className="flex-1 text-xl ml-4 text-right flex justify-end items-center">
        <div className="flex items-center mr-4">
          <Button
            className={clsx("flex items-center px-2 py-1 text-depalmaBlack rounded-full", device?._activeCall ? 'bg-green-500' : 'bg-depalmaWhite')}
            onClick={handleClickConnect}
            theme={'none'}
          >
            <FaPhone className="mr-4"/>
            <span className={clsx("w-3 h-3 rounded-full", ledClassName)} style={{boxShadow}}></span>
          </Button>
        </div>
        {/* <div className="text-sm">{getIdentity() ? getIdentity().name : null}</div> */}
        <div className="flex-initial text-xl ml-4">
          <AccountButton
            menuIsOpen={menuIsOpen}
          />
        </div>
      </div>

      {isOpen ? <CallDialogue /> : null}
    </nav>
  );
};

export default HeaderBar;
