import { useCallback, useEffect, useState } from "react";

const recordsStore = {};
const schemas = {};
const fieldsFor = {};

const defaultParams = {
  sortBy: undefined,
  sortDir: undefined,
  filterBy: undefined,
  search: undefined,
};

const defaultStore = {
  endpoint: null,
  schema: null,
  fields: null,
};

const istokenError = e => {
  if (e.isAxiosError) {
    const wwwAuth = e.response?.headers["www-authenticate"];
    return (
      wwwAuth?.match &&
      (wwwAuth.match('error_description="The access token expired"') ||
        wwwAuth.match('error_description="The access token is invalid"'))
    );
  }
  return false;
};

export const useListApi = (apiId, apiOptions, initialParams = null) => {
  const { index } = useApi(apiId, apiOptions);

  const [params, setParams] = useState({ ...defaultParams, ...initialParams });
  const [noOfRecords, setNoOfRecords] = useState(0);
  const [status, setStatus] = useState(null);
  const [records, setRecords] = useState([]);
  const [batches, setBatches] = useState({});

  const load = useCallback(
    async (batch = 0, loadParams = params) => {
      const { sortBy, sortDir, ...rest } = loadParams;
      const indexParams = {
        ...rest,
        sortBy: sortBy ? [sortBy, sortDir].join(" ") : undefined,
      };
      setStatus('loading');
      const [response, error] = await index({
        offset: batch * 100,
        params: indexParams,
      });

      setStatus(error ? 'error' : null);

      if (!error) {
        const { noOfRecords, start, end, records } = response;
        setNoOfRecords(noOfRecords);
        setRecords(r => [
          ...r.slice(0, start),
          ...records,
          ...r.slice(start + records.length),
        ]);
      }
    },
    [index, params]
  );

  const getRow = useCallback(
    ({ index: rowIdx }) => {
      if (records[rowIdx]) {
        return records[rowIdx];
      } else {
        const batch = parseInt(rowIdx / 100);
        if (!batches[batch]) {
          batches[batch] = true;
          setBatches(b => ({ ...b, [batch]: true }));
          load(batch);
        }
        return (records[rowIdx] = { _loading: true });
      }
    },
    [load, batches, records]
  );

  const { sortBy, sortDir, filterBy, search } = params;
  const setSortBy = useCallback((by, dir) => {
    setParams(p => ({ ...p, sortBy: by, sortDir: dir }));
    setRecords([]);
    setBatches({});
  }, []);
  const setFilterBy = useCallback(() => {}, []);
  const setSearch = useCallback(
    value => {
      const { search, ...rest } = params;
      const updParams = { ...rest, search: value || undefined };
      setParams(updParams);
      setNoOfRecords(0);
      setRecords([]);
      setBatches({});
      load(0, updParams);
    },
    [params, load]
  );

  return {
    filterBy,
    getRow,
    load,
    noOfRecords,
    search,
    setFilterBy,
    setSearch,
    setSortBy,
    sortBy,
    sortDir,
    status,
  };
};

export const useGetApi = (options) => {
  const { client, unauthorized } = options || {};

  const get = useCallback(
    async id => {
      try {
        if (recordsStore[id]) {
          return recordsStore[id];
        } else {
          const { data } = await client.get(`blocks/${id}`);
          return (recordsStore[id] = data);
        }
      } catch (e) {
        if (istokenError(e)) {
          unauthorized && unauthorized();
        }
        throw e;
      }
    },
    [client, unauthorized]
  );

  return {
    get,
  };
};

const _index = async (client, unauthorized, apiId, options) => {
  try {
    const { params = undefined, offset = 0, limit = 100 } = options || {};
    const { data, headers } = await client.get(apiId, {
      params,
      headers: { Range: `items=${offset}-${offset + limit - 1}` },
    });
    const [range, noOfRecords] = headers["content-range"]
      .replace("items ", "")
      .split("/");
    const [start, end] = range.split("-");

    return [
      {
        records: data,
        noOfRecords: parseInt(noOfRecords),
        start: parseInt(start),
        end: parseInt(end),
      },
      null,
    ];
  } catch (e) {
    if (istokenError(e)) {
      unauthorized && unauthorized();
    }
    return [{}, { error: e }];
  }
};

export const useIndexApi = (apiOptions) => {
  const { client, unauthorized } = apiOptions || {};

  const index = useCallback(
    async (apiId, options) => await _index(client, unauthorized, apiId, options),
    [client, unauthorized]
  );

  return {
    index,
  };
};

export const useApi = (apiId, apiOptions) => {
  const { client, unauthorized } = apiOptions || {};

  const [store, setStore] = useState({
    ...defaultStore,
    endpoint: apiId,
  });
  const { endpoint, schema, fields } = store;

  useEffect(() => {
    if (apiId !== endpoint) {
      setStore({
        ...defaultStore,
        endpoint: apiId,
      });
    }
  }, [apiId]);

  const get = useCallback(
    async id => {
      try {
        if (recordsStore[id]) {
          return recordsStore[id];
        } else {
          const { data } = await client.get(`${endpoint}/${id}`);
          return (recordsStore[id] = data);
        }
      } catch (e) {
        if (istokenError(e)) {
          unauthorized && unauthorized();
        }
        throw e;
      }
    },
    [client, unauthorized, endpoint]
  );

  const getMany = useCallback(
    async ids => {
      try {
        let idsParam = ids.filter(id => !recordsStore[id]).join(",");
        const { data } = await client.get(`${endpoint}/${idsParam}`);

        return ids.map(id => {
          if (recordsStore[id]) {
            return recordsStore[id];
          } else {
            let record = data[idsParam.indexOf(id)];
            return (recordsStore[id] = record);
          }
        });
      } catch (e) {
        if (istokenError(e)) {
          unauthorized && unauthorized();
        }
        throw e;
      }
    },
    [client, unauthorized, endpoint]
  );

  const getSchema = useCallback(async () => {
    try {
      if (schema) {
        return schema;
      } else if (schemas[endpoint]) {
        setStore(store => ({ ...store, schema: schemas[endpoint] }));
        return schemas[endpoint];
      } else {
        const { data } = await client.get(`${endpoint}/.schema`);
        setStore(store => ({ ...store, schema: data }));
        schemas[endpoint] = data;
        return data;
      }
    } catch (e) {
      if (istokenError(e)) {
        unauthorized && unauthorized();
      }
      throw e;
    }
  }, [client, unauthorized, schema, endpoint]);

  const getFields = useCallback(async () => {
    try {
      if (fields) {
        return fields;
      } else if (fieldsFor[endpoint]) {
        setStore(store => ({ ...store, fields: fieldsFor[endpoint] }));
        return fieldsFor[endpoint];
      } else {
        const { data } = await client.get(`${endpoint}/.fields`);
        setStore(store => ({ ...store, fields: data }));
        fieldsFor[endpoint] = data;
        return data;
      }
    } catch (e) {
      if (istokenError(e)) {
        unauthorized && unauthorized();
      }
      throw e;
    }
  }, [client, unauthorized, fields, endpoint]);

  const index = useCallback(
    async options => await _index(client, unauthorized, endpoint, options),
    [client, unauthorized, endpoint]
  );

  const create = useCallback(
    async params => {
      try {
        const { data } = await client.post(endpoint, { [endpoint]: params });
        return (recordsStore[data._id] = data);
      } catch (e) {
        if (istokenError(e)) {
          unauthorized && unauthorized();
        }
        return null;
      }
    },
    [client, unauthorized, endpoint]
  );

  const update = useCallback(
    async (id, params) => {
      try {
        const { data } = await client.patch(`${endpoint}/${id}`, {
          [endpoint]: params,
        });
        recordsStore[id] = data;
        return data;
      } catch (e) {
        if (istokenError(e)) {
          unauthorized && unauthorized();
        }
        return null;
      }
    },
    [client, unauthorized, endpoint]
  );

  const destroy = useCallback(
    async id => {
      try {
        const { data } = await client.delete(`${endpoint}/${id}`);
        delete recordsStore[id];
        return data;
      } catch (e) {
        if (istokenError(e)) {
          unauthorized && unauthorized();
        }
        return null;
      }
    },
    [client, unauthorized, endpoint]
  );

  return {
    create,
    destroy,
    get,
    getMany,
    getFields,
    getSchema,
    index,
    update,
  };
};
