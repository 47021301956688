if (typeof document !== 'undefined') {
  document.addEventListener('click',    handler.bind(null, 'click'), true);
  document.addEventListener('keydown',  handler.bind(null, 'keydown'));
  document.addEventListener('keyup',    handler.bind(null, 'keyup'));
}

let listeners = {};
let stack = [];
let lastId = -1;

function handler(type, event) {
  const id = stack[stack.length-1];
  const listenObject = listeners[id];

  if (listenObject) {
    const handler = listenObject[type];

    if (typeof handler === 'function') {
      handler(event);
    }
  }
};

const eventStacker = {
  addListenable(listenObject/* { 'click': clickHandler, 'keydown': keydownHandler, 'keyup': keyupHandler } */) {
    const id = `${++lastId}`;
    listeners[id] = listenObject;
    stack.push(id);

    return id;
  },

  removeListenable(id) {
    if (stack.indexOf(id) !== -1) {
      delete listeners[id];
      stack.splice(stack.indexOf(id), 1);
    }
  }
};

export default eventStacker;
