// import React, { useCallback, useEffect } from "react";
// import { navigate } from "gatsby";
// import { useForm } from "react-hook-form";

// import Button from "../../components/Button";
// import { useIdentityStateContext } from "../../contexts/IdentityContext";
// import logoWorkwearWhite from "../../images/logo-depalma-workwear-white.svg";
// import { getFabriqo } from "../../services/auth";
// import { useLoginContext } from "../../contexts/LoginContext";

const Login  = props => {
  // const { error, login, logout } = useIdentityStateContext();
  // const { getToken } = useLoginContext();
  // const { handleSubmit, register } = useForm({ mode: "onChange" });

  // useEffect(() => {
  //   if (getToken()) {
  //     // Dubble check that we do in fact still have a token in storage and not just cached in context.
  //     if (!getFabriqo().getAccessToken()) {
  //       logout();
  //     } else {
  //       navigate(`/app`);
  //     }
  //   }
  // }, []);

  // const onSubmit = useCallback(async formData => {
  //   login(formData)
  //     .then(response => {
  //       if (!response.error) {
  //         response.metadata?.requestedRoute
  //           ? navigate(response.metadata.requestedRoute)
  //           : navigate("/app");
  //       }
  //     });
  // }, []);

  // return (
  //   <div className="flex-1 flex flex-col min-h-screen bg-depalmaBlue text-depalmaWhite justify-between">
  //     <div className="flex-1"></div>
  //     <main className="mb-auto flex-0 text-center">
  //       <img
  //         src={logoWorkwearWhite}
  //         alt="DePalma Logo"
  //         className="h-16 lg:h-20 mx-auto mb-16"
  //       />
  //       <h1 className="text-center text-2xl mb-4">Log in</h1>
  //       <form onSubmit={handleSubmit(onSubmit)} className="mx-auto w-80">
  //         <div className="mb-4">
  //           <input
  //             {...register('email')}
  //             type="email"
  //             className="block shadow appearance-none border border-gray-500 text-depalmaBlack rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
  //             placeholder="Email"
  //           />
  //         </div>

  //         <div className="mb-4">
  //           <input
  //             {...register('password')}
  //             type="password"
  //             className="block shadow appearance-none border border-gray-500 text-depalmaBlack rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
  //             placeholder="Password"
  //           />
  //         </div>

  //         {error ? (
  //           <p className="italix text-sm text-depalmaRed">
  //             {error.description}
  //           </p>
  //         ) : null}

  //         <Button
  //           label="Login"
  //           theme="white"
  //           type="submit"
  //         />
  //       </form>
  //     </main>
  //     <div className="flex-1"></div>
  //   </div>
  // );
  return null;
};

export default Login;
