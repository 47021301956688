const apiEndpoints = {
  "happyrabbit:individual": "individual",
  "happyrabbit:location": "location",
  "happyrabbit:organisation": "organisation",
};

const paths = {
  "happyrabbit:individual": "/app/crm/individuals",
  "happyrabbit:location": "/app/crm/locations",
  "happyrabbit:organisation": "/app/crm/organisations",
};

export const apiIdForSchema = name => apiEndpoints[name];

export const listPathForSchema = name => paths[name];
