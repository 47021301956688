import React, { useCallback } from "react";
import clsx from "clsx";

import { useTwilio } from "../../contexts/TwilioContext";
import { FaPhone } from "react-icons/fa";
import { FieldWrapper, LabelWrapper } from "./Wrappers";
import { useWatch } from "react-hook-form";

const FieldPhone = props => {
  const {
    attribute,
    description = null,
    errors = {},
    isDirty = false,
    label,
    layout = 'vertical',
    placeholder = label,
    register,
    watch,
    ...rest
  } = props;

  const value = useWatch({ name: attribute });

  const { connect, setIsOpen, setPhoneNumber } = useTwilio();

  const handleClickPhone = useCallback(() => {
    connect().then(device => {
      if (device) {
        setPhoneNumber(value);
        setIsOpen(true);
      }
    })
  }, [connect, value])

  const renderActions = useCallback(
    () => value ? (
      <button type="button" onClick={handleClickPhone}>
        <FaPhone className="text-green-700" />
      </button>
    ) : null,
    [value, handleClickPhone]
  );

  return (
    <LabelWrapper label={label} layout={layout}>
      <FieldWrapper layout={layout} renderActions={renderActions}>
        <input
          {...rest}
          {...register(attribute)}
          className={clsx(
            "block flex-1 shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline",
            { "border-red-500": !!errors[attribute] },
            { "border-gray-500": !errors[attribute] },
            { "bg-orange-200": isDirty },
          )}
          placeholder={placeholder}
          type="text"
        />
      </FieldWrapper>
    </LabelWrapper>
  )
};

export default FieldPhone;
