import React from "react";
import { FaBuilding, FaTrash } from "react-icons/fa";

import DetailView from "../../parts/DetailView";
import Tabs from "../../components/Tabs";
import Locations from "./Locations";
import Details from "./Details";
import Meta from "../../parts/Meta";
import Button from "../../components/Button";
import Additional from "../../parts/Additional";
import UnsavedChanges from "../../parts/UnsavedChanges";

import Actions from "./Actions";
import Notes from "../../parts/Notes";

const TABS = [
  { key: 'tabDetails', label: 'Details' },
  { key: 'tabLocations', label: 'Locations' },
  { key: 'tabNotes', label: 'Notes' },
  { key: 'tabAdditional', label: 'Additional' },
];

const OrganisationView = props => {
  const {
    id,
    block,
    fieldDefinitions,
    fields,
    handleDestroy,
    onSubmit,
    schema,
  } = props;

  const tabProps = { block, fields, onSubmit, schema };

  return (
    <>
      <div className="flex items-start justify-between">
        <Actions onSubmit={onSubmit} />
      </div>

      <div className="flex flex-1">
        <div className="w-3/4 overflow-y-scroll">
          <Tabs tabs={TABS}>
            {({ openTab }) => (
              <>
                <div className={openTab === 'tabDetails' ? "min-h-full flex-1 flex flex-col" : "hidden"} id="tabDetails">
                  <Details {...tabProps} active={openTab === 'tabDetails'} />
                </div>

                <div className={openTab === 'tabLocations' ? "min-h-full flex-1 flex flex-col" : "hidden"} id="tabLocations">
                  <Locations {...tabProps} active={openTab === 'tabLocations'} />
                </div>

                <div className={openTab === 'tabNotes' ? "min-h-full flex-1 flex flex-col" : "hidden"} id="tabNotes">
                  <Notes {...tabProps} active={openTab === 'tabNotes'} />
                </div>

                <div className={openTab === 'tabAdditional' ? "min-h-full flex-1 flex flex-col" : "hidden"} id="tabAdditional">
                  <Additional {...tabProps} active={openTab === 'tabAdditional'} />
                </div>
              </>
            )}
          </Tabs>
        </div>

        <div className="w-1/4 pl-4 border-l ml-4">
          <Meta block={block} />

          <UnsavedChanges
            fieldDefinitions={fieldDefinitions}
            block={block}
          />

          <h5 className="text-sm font-bold text-gray-700 mb-2">Danger zone</h5>
          <div className="mb-4">
            <Button onClick={() => handleDestroy()} theme="danger">
              <FaTrash className="inline mr-1" />
              Delete this record
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default props => (
  <DetailView
    {...props}
    apiId={'organisation'}
    listIcon={FaBuilding}
    listLabel={'Organisations'}
    listPath={'/app/crm/organisations'}
  >
    {p => (
      <OrganisationView {...p}/>
    )}
  </DetailView>
);
