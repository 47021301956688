import { Link } from "gatsby";
import React from "react";
import { FaTachometerAlt, FaBuilding, FaUserAlt, FaMapMarkerAlt } from "react-icons/fa";
import * as FaIcons from "react-icons/fa";

import { appConfig } from "../../components/PrivateRoute";

const appCrmEndpoints = appConfig.endpoints.filter(({ localPath }) => localPath.match(/^\/app\/crm/))

const renderEndpointMenu = (endpoint, i) => {
  const { localPath, title, iconName } = endpoint;

  const Icon = FaIcons[iconName];
  return (
    <Link
      key={i}
      to={localPath}
      className="flex items-center px-4 py-2 hover:bg-black bg-opacity-25"
    >
      {Icon ? <Icon className="mr-2"/> : null}
      {title}
    </Link>
  );
};

const SideMenu = props => {

  return (
    <div className="bg-depalmaBlue text-depalmaWhite flex-1">
      <Link to="/app/crm" className="flex items-center px-4 py-2 hover:bg-black bg-opacity-25">
        <FaTachometerAlt className="mr-2"/> Dashboard
      </Link>
      <div className="border-b" ></div>

      <Link to="/app/crm/marketing_profiles" className="flex items-center px-4 py-2 hover:bg-black bg-opacity-25">
        <FaUserAlt className="mr-2"/> Marketing Profiles
      </Link>
      <Link to="/app/crm/organisations" className="flex items-center px-4 py-2 hover:bg-black bg-opacity-25">
        <FaBuilding className="mr-2"/> Organisations
      </Link>
      <Link to="/app/crm/individuals" className="flex items-center px-4 py-2 hover:bg-black bg-opacity-25">
        <FaUserAlt className="mr-2"/> Individuals
      </Link>
      <Link to="/app/crm/locations" className="flex items-center px-4 py-2 hover:bg-black bg-opacity-25">
        <FaMapMarkerAlt className="mr-2"/> Locations
      </Link>

      {appCrmEndpoints.map(renderEndpointMenu)}
    </div>
  );
};

export default SideMenu;
