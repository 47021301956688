import React, { useCallback, useEffect, useState } from "react";
import { FaBuilding } from "react-icons/fa";
import { navigate } from "gatsby";
import { useListApi } from "@fabriqo/api-rest";
import {Table} from "@fabriqo/components";

import { useApi } from "../../api/fabriqo";
import { SearchDialogue } from "../../parts/CompanyRegistry";
import { useTwilio } from "../../contexts/TwilioContext";
import { ContentLayout } from "../CRM";
import LinkCellNavigate from "../DocumentList/LinkCellNavigate";

import Actions from "./Actions";
import AddDialogue from "./AddDialogue";

const tableComponents = {
  LinkCell: LinkCellNavigate,
};

const OrganisationsList = props => {
  const {
    apiId = 'organisation',
    children,
  } = props;
  const [addNew, setAddNew] = useState(null);
  const [searchRegistry, setSearchRegistry] = useState(null);

  const index = useListApi(apiId);
  const { create } = useApi(apiId);

  const { noOfRecords, load } = index;
  useEffect(() => { noOfRecords === 0 && load() }, []);

  const handleSubmitAdd = useCallback(async data => {
    try {
      const newOrganisation = await create(data);
      setAddNew(null);
      navigate(`/app/crm/organisations/${newOrganisation._id}`);
    } catch(e) {
      console.log(e);
    }
  }, []);

  const { connect, setIsOpen, setPhoneNumber } = useTwilio();

  const handleClickPhone = useCallback(phoneNumber => {
    connect().then(device => {
      if (device) {
        setPhoneNumber(phoneNumber);
        setIsOpen(true);
      }
    })
  }, [connect])

  return (
    <>
      <ContentLayout>
        <div className="flex items-start justify-between">
          <h1 className="text-lg mb-2">
            <FaBuilding className="mr-2 inline" />
            Organisations
          </h1>
        </div>

        <Actions
          index={index}
          onClickAdd={() => setAddNew(true)}
          onClickSearchRegistery={() => setSearchRegistry(true)}
        />

        <Table
          components={tableComponents}
          index={index}
          baseLinkURL={'/app/crm/organisations'}
        />

        {addNew ? (
          <AddDialogue
            onClose={() => setAddNew(null)}
            onSubmit={handleSubmitAdd}
          />
        ) : null}

        {searchRegistry ? (
          <SearchDialogue
            onClose={() => setSearchRegistry(null)}
          />
        ) : null}
      </ContentLayout>
    </>
  );
};

export default OrganisationsList;
