import React, { useCallback } from "react";

import { renderFieldContext } from "../../components/Forms";
import { filterAndSort } from "../../utils/blocks";

const summaryFieldNames = ["contact", "name", "locationType"];
const addressFieldNames = [
  "address",
  "address2",
  "city",
  "zip",
  "state",
  "country",
];

const Details = props => {
  const { fields, block, schema } = props;

  const renderField = useCallback(
    field =>
      renderFieldContext({
        field,
        layout: "horizontal",
      }),
    []
  );

  return (
    <div className="">
      <div className="mb-8">
        <h5 className="ml-40 mb-2 text-xs uppercase text-gray-600">Summary</h5>
        {fields.map(renderField)}
      </div>
    </div>
  );
};

export default Details;
