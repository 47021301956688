import { Link } from "gatsby";
import React from "react";

const LinkCellNavigate = props => {
  const {
    baseLinkURL,
    cellData,
    paramKey,
    rowData,
  } = props;

  const param = rowData[paramKey];

  if (!param) return null;

  return (
    <Link
      className={'text-blue-500'}
      to={`${baseLinkURL}/${param}`}
      title={cellData || param}
    >
      {(cellData || param).substr(0, 8)}
    </Link>
  );
};

export default LinkCellNavigate;
