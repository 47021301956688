import React, { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";

const Form = props => {
  const {
    block,
    children,
    confirmDestroyMessage,
    destroy,
    id,
    listPath,
    update,
    ...rest
  } = props;

  const methods = useForm({ mode: "onChange", defaultValues: block });
  const { formState, handleSubmit, reset } = methods;
  const { dirtyFields } = formState || {};

  const handleUpdate = useCallback(
    async data => {
      return await update(id, data);
    },
    [update, id]
  );

  const handleUpdateChanges = useCallback(
    async (data, dirtyFields) => {
      const changedParams = Object.keys(dirtyFields).reduce(
        (acc, k) => ({ ...acc, [k]: data[k] }),
        {}
      );
      const block = await handleUpdate(changedParams);
      reset(block);
    },
    [handleUpdate]
  );

  const handleDestroy = useCallback(async () => {
    if (confirm(confirmDestroyMessage)) {
      try {
        destroy().then(() => listPath && navigate(listPath));
      } catch (e) {
        console.log("Failed to destroy record");
      }
    }
    return await destroy(id);
  }, [destroy, id, listPath, confirmDestroyMessage]);

  const onSubmit = useCallback(
    handleSubmit(data => handleUpdateChanges(data, dirtyFields), [dirtyFields])
  );


  return (
    <FormProvider {...methods}>
      {children({
        ...rest,
        block,
        handleDestroy,
        handleUpdate,
        handleUpdateChanges,
        id,
        listPath,
        onSubmit,
      })}
    </FormProvider>
  );
};

export default Form;
