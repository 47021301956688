import React from "react";
import clsx from 'clsx';

export const THEMES = {
  'black': "bg-depalmaBlack text-depalmaWhite focus:bg-opacity-90 hover:bg-opacity-90 disabled:bg-opacity-75",
  'danger': "border border-depalmaRed bg-white text-depalmaRed hover:bg-depalmaRed hover:text-depalmaWhite disabled:bg-opacity-75",
  'ghost': "bg-transparent text-depalmaBlack border border-depalmaBlack hover:bg-black hover:bg-opacity-10 focus:bg-black focus:bg-opacity-10 disabled:text-gray-500 disabled:border-gray-500",
  'white': "bg-depalmaWhite text-depalmaBlack focus:bg-depalmaWhiteAlt hover:bg-depalmaWhiteAlt disabled:text-gray-500",
  'success': "bg-green-500 text-depalmaWhite focus:bg-opacity-90 hover:bg-opacity-90 disabled:text-gray-600 disabled:bg-green-300",
  'royal': "bg-royalBlue text-depalmaWhite focus:bg-opacity-90 hover:bg-opacity-90 disabled:text-gray-600 disabled:bg-blue-300",
  'warning': "bg-depalmaRed text-depalmaWhite focus:bg-opacity-90 hover:bg-opacity-90 disabled:bg-opacity-75",
  'none': ' ',
};

export const defaultClassName = "text-xs averta-bold py-2 px-8 uppercase tracking-wider box-border focus:outline-none whitespace-nowrap max-w-full overflow-hidden overflow-ellipsis";

const Button = (props) => {
  const {
    border = false,
    buttonRef = null,
    children = null,
    className = defaultClassName,
    fullWidth = false,
    height = 10,
    theme = "ghost",
    type = "button",
    label,
    ...rest
  } = props;

  const themeClassNames = THEMES[theme] || THEMES.white;

  return (
    <button
      {...rest}
      className={clsx(className, themeClassNames, { 'w-full': fullWidth, [`h-${height}`]: height })}
      ref={buttonRef}
      type={type}
    >
      {children || label}
    </button>
  );
};

export default Button;
