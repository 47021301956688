/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import clsx from "clsx";

import Header from "../Header";
import Footer from "../Footer";

export const withAppLayout = Component => (
  props => (
    <AppLayout>
      <Component {...props} />
    </AppLayout>
  )
);

const AppLayout = props => {
  const { children } = props;

  return (
    <div className="flex-1 flex flex-col min-h-screen justify-between">
      <Header />

      <main className={clsx("mb-auto pt-16 flex-1 flex")}>
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default AppLayout;
