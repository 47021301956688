import React, { useCallback, useMemo } from "react";
import { renderFieldContext } from "../../components/Forms";

const filterAndSort = (fields, block) =>
  useMemo(() => {
    const fieldNames = fields.map(f => f.name);
    return Object.keys(block)
      .filter(attr => attr[0] !== "_" && fieldNames.indexOf(attr) === -1)
      .sort((a, b) => a.localeCompare(b));
  }, [fields, block]);

const Additional = props => {
  const { fields, block } = props;

  const additionalFields = filterAndSort(fields, block);

  const renderField = useCallback(
    field =>
      renderFieldContext({
        field: { name: field },
        layout: "horizontal",
      }),
    []
  );

  return (
    <div className="">
      <div className="mb-8">
        <h5 className="ml-40 mb-2 text-xs uppercase text-gray-600">
          Fields not in Schema
        </h5>
        {additionalFields.map(renderField)}
      </div>
    </div>
  );
};

export default Additional;
