import React, { useEffect, useRef, useState } from "react";

import "easymde/dist/easymde.min.css";
import "./index.css";

// Cannot import EasyMDE straight away because it breaks Gatsby SSR build.
let EasyMDE;

const EasyMDEReact = props => {
  const {
    onChange,
    value,
  } = props;

  const textareaRef = useRef(null);
  const easyMdeRef = useRef(null);
  const [loaded, setLoaded] = useState(!!EasyMDE);

  useEffect(() => {
    if (EasyMDE)
      return;
    (async () => {
      const easymde = await import("easymde");
      EasyMDE = easymde.default;
      setLoaded(true);
    })();
  }, []);

  // Effect hook to initialize and configure the editor
  useEffect(() => {
    if (!loaded || !EasyMDE)
      return;

    const easyMDE = easyMdeRef.current = new EasyMDE({
      element: textareaRef.current,
      initialValue: value || '',
      minHeight: '100px',
      spellChecker: false,
      status: false,
    });

    easyMDE.codemirror.on("change", () => {
      onChange(easyMDE.value());
    });

    return () => {
      easyMdeRef.current.toTextArea();
      easyMdeRef.current = null;
    }
  }, [loaded, EasyMDE]);

  // Effect hook to compare external value prop and manually
  // override the value if it differs compare to the editor
  // value.
  useEffect(() => {
    const easyMDE = easyMdeRef.current;
    if (easyMDE && typeof value !== 'undefined' && value !== easyMDE.value()) {
      easyMDE.value(value || '');
    }
  }, [value, easyMdeRef.current]);

  return (
    <div className="EasyMDEContainer">
      <textarea ref={textareaRef} />
    </div>
  );
};

export default EasyMDEReact;
