import React, { useCallback, useEffect, useState } from "react";
import { navigate } from "gatsby-link";
import * as FaIcons from "react-icons/fa";
import { useApi, useListApi } from "@fabriqo/api-rest";
import {Table} from "@fabriqo/components";

import { ContentLayout } from "../CRM";
import Actions from "./Actions";
import AddDialogue from "./AddDialogue";
import LinkCellNavigate from "./LinkCellNavigate";

const tableComponents = {
  LinkCell: LinkCellNavigate,
};

const DocumentList = props => {
  const { endpoint } = props;
  const { apiId, title, iconName, localPath } = endpoint || {};

  const [addNew, setAddNew] = useState(null);

  const index = useListApi(apiId);
  const { create } = useApi(apiId);

  const { noOfRecords, load } = index;
  useEffect(() => { noOfRecords === 0 && load() }, []);

  const handleSubmitAdd = useCallback(async data => {
    try {
      const block = await create(data);
      setAddNew(null);
      navigate(`${localPath}/${block._id}`);
    } catch(e) {
      console.log(e);
    }
  }, []);

  const Icon = FaIcons[iconName];

  return (
    <>
      <ContentLayout>
        <div className="flex items-start justify-between">
          <h1 className="text-lg mb-2">
            {Icon ? <Icon className="mr-2 inline" /> : null}
            {title}
          </h1>
        </div>

        <Actions
          index={index}
          onClickAdd={() => setAddNew(true)}
        />

        <Table
          baseLinkURL={localPath}
          components={tableComponents}
          index={index}
        />

        {addNew ? (
          <AddDialogue
            index={index}
            onClose={() => setAddNew(null)}
            onSubmit={handleSubmitAdd}
            title={`Add ${title}`}
          />
        ) : null}
      </ContentLayout>
    </>
  );
};

export default DocumentList;
