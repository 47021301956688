import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import { FaSignOutAlt, FaCog, FaUserAlt } from "react-icons/fa";

import eventStacker from "../../utils/eventStacker";
import { useLoginContext } from "../../contexts/LoginContext";
import Button from "../../components/Button";

const AccountMenu = props => {
  const { account, logout, onClose } = props;

  const ref = useRef();

  useEffect(() => {
    const eventStackId = eventStacker.addListenable({
      // Closes on click outside of the Modal frame
      click: (e) => ref.current && !ref.current.contains(e.target) && onClose(),

      // Closes on Esc key being pressed
      keydown: (e) => e.keyCode === 27 && onClose(),
    });

    return () => eventStacker.removeListenable(eventStackId);
  }, [onClose, ref]);

  const handleLogoutClick = () => {
    logout();
    onClose();
  };

  if (!account)
    return null;

  return (
    <div className="fixed lg:absolute right-0 pr-1 md:pr-0 md:right-auto lg:right-0 left-0 pl-1 md:pl-20 lg:pl-0 lg:left-auto">
      <div className="shadow-lg bg-white text-depalmaBlack text-sm w-full md:w-auto text-left" ref={ref}>
        <div className="py-2 pl-8 pr-4 relative">
          <FaUserAlt className="text-xl pl-2 absolute left-0" />
          {/* <img src={UserBlack} alt={'User'} className={clsx("h-4 absolute left-0 pl-2")} /> */}
          {account.email}
        </div>
        <Link
          className="block py-2 pl-8 pr-4 hover:bg-gray-200 relative"
          to={'/app/profile'}
        >
          <FaCog className="absolute left-0 pl-2 text-xl" />
          Account Settings
        </Link>
        <Button
          className="block w-full text-left py-2 pl-8 pr-4 hover:bg-gray-200 relative"
          theme="none"
          onClick={handleLogoutClick}
        >
          <FaSignOutAlt className="absolute left-0 pl-2 text-xl" />
          Logout
        </Button>
      </div>
    </div>
  );
}

const AccountButton = props => {
  const { menuIsOpen } = props;
  const [accountOpen, setAccountOpen] = useState(false);
  const { getAccount, logout } = useLoginContext();
  const account = getAccount();

  const handleClose = useCallback(() => {
    setAccountOpen(false);
  }, []);

  const handleClick = useCallback(() => {
    if (account) {
      setAccountOpen(true);
    } else {
      //setSignInUp('signUp');
    }
  }, [account])

  return (
    <div className="relative">
      <Button className="focus:outline-none" onClick={handleClick} theme="none" height={null}>
        {account ? (
          <span className="inline-flex h-6 w-6 mb-2 rounded-full justify-center leading-6 bg-depalmaBlue text-depalmaWhite text-sm">
            {account.email[0].toUpperCase()}
          </span>
        ) : (
          <FaUserAlt className="text-2xl" />
        )}
      </Button>

      {accountOpen ? (
        <AccountMenu logout={logout} onClose={handleClose} account={account} />
      ) : null}
    </div>
  );
}

export default AccountButton;
