import React from 'react';
import { useFormContext } from 'react-hook-form';

import FieldCheckbox from './field-checkbox';
import FieldIdentitySelect from './field-identity-select';
import FieldMarkdown from './field-markdown';
import FieldMultiselect from './field-multi-select';
import FieldPhone from './field-phone';
import FieldReference from './field-reference';
import FieldSelect from './field-select';
import FieldText from './field-text';
import FieldTextarea from './field-textarea';

export const renderFieldContext = props => {
  const { control, formState, register, watch } = useFormContext();
  return renderField({ ...props, control, formState, register, watch });
}

export const renderField = props => {
  const {
    control,
    formState,
    field,
    layout,
    prefix,
    register,
    watch,
  } = props;
  const { name, title, to, type } = field || {};
  
  const { dirtyFields } = formState;

  const attribute = [prefix, name].filter(Boolean).join('.');

  const fieldProps = {
    control,
    key: name,
    attribute: attribute,
    isDirty: dirtyFields[name],
    label: title || name,
    layout,
    register,
    watch,
  };

  if (name === 'phone') {
    return <FieldPhone {...fieldProps} />
  } else if (type === 'reference') {
    return <FieldReference {...fieldProps} field={field} />
  } else if (type === 'markdown') {
    return <FieldMarkdown {...fieldProps} />
  } else {
    return <FieldText {...fieldProps} />
  }
};

export {
  FieldCheckbox,
  FieldIdentitySelect,
  FieldMarkdown,
  FieldMultiselect,
  FieldReference,
  FieldSelect,
  FieldText,
  FieldTextarea,
};
