import React from "react";
import AppLayout, { withAppLayout } from "../../parts/AppLayout";
import SideMenu from "./SideMenu";

export const withContentLayout = Component => (
  props => (
    <AppLayout>
      <ContentLayout>
        <Component {...props} />
      </ContentLayout>
    </AppLayout>
  )
);

const ContentLayout = props => {
  const { children } = props;

  return (
    <div className="flex-1 flex">
      <div className="flex-0 w-48">
        <div className="p-2 min-h-full flex flex-col">
          <SideMenu/>
        </div>
      </div>
      <div className="flex-1">
        <div className="p-2 min-h-full flex flex-col">
          <div className="bg-white text-depalmaBlack flex-1 min-h-full border border-gray-500 p-2 flex flex-col">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAppLayout(ContentLayout);
