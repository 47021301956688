import React, { useRef } from "react";
import axios from "axios";

import { useLoginContext } from "../../contexts/LoginContext";

const loginHost = process.env.NODE_ENV === 'production' ? 'https://login.depalmaworkwear.com' : `http://localhost:9778`;

export const appConfig = {
  endpoints: [
    { title: 'Account', apiId: 'account', localPath: '/app/crm/accounts', iconName: 'FaBook' },
  ],
};

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { getToken, setToken, fabriqoClient } = useLoginContext();
  const accountRef = useRef(null);
  if (typeof window === 'undefined')
    return null;

  // if (!isLoggedIn() && location.pathname !== `/app/login`) {
  if (!getToken()) {
    // Not logged in means that there is no access token stored in local storage. However, there
    // could still be a http only cookie set, but since we cannot access it directly, we must query
    // the account endpoint to see.
    
    if (accountRef.current)
      return null; // Already an account request in progress

    if (location.pathname.match(/^\/app\/oauth/)) {
      return <Component {...rest} />
    }
    accountRef.current = axios.get('/.netlify/functions/account')
      .then(({ data }) => {
        setToken(data);
        // Remove request ref once done so that an immediate logout action would not ignore a new call to /account
        accountRef.current = null;
      })
      .catch(({ response }) => {
        const { data } = response;
        let loginUrl = `${loginHost}?site=${location.href}`;
        if (data.error === 'missing required roles') {
          loginUrl += `&errorMessage=${encodeURIComponent('You are not authorized to view this page')}`;
        }
        window.location = loginUrl;
      })
    return null;
  }
  return fabriqoClient ? <Component {...rest} /> : null;
}
export default PrivateRoute;
