import React, { useCallback } from "react";

import { renderFieldContext } from "../../components/Forms";
import { filterAndSort } from "../../utils/blocks";

const summaryFieldNames = ["name", "alias", "accountNumber", "businessRegistration", "invoiceVAT", "customerType", "chain", "segment"];
const contactFieldNames = ["email", "phone", "website", "domain"];
const socialFieldNames = ["facebook", "instagram", "youtube", "linkedin"];

const Details = props => {
  const { block, fields, schema } = props;

  const summaryFields = filterAndSort(fields, summaryFieldNames);
  const contactFields = filterAndSort(fields, contactFieldNames);
  const socialFields = filterAndSort(fields, socialFieldNames);

  const renderField = useCallback(
    field =>
      renderFieldContext({
        field,
        layout: "horizontal",
      }),
    []
  );

  return (
    <div className="flex-1 flex flex-col min-h-full">
      <div className="mb-8">
        <h5 className="ml-40 mb-2 text-xs uppercase text-gray-600">Summary</h5>
        {summaryFields.map(renderField)}
      </div>

      <div className="mb-8">
        <h5 className="ml-40 mb-2 text-xs uppercase text-gray-600">Contact</h5>
        {contactFields.map(renderField)}
      </div>

      <div className="mb-8">
        <h5 className="ml-40 mb-2 text-xs uppercase text-gray-600">Social</h5>
        {socialFields.map(renderField)}
      </div>
    </div>
  );
};

export default Details;
