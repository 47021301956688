import React from "react";
import { useApi } from "../../api/fabriqo";
import Loading from "../../components/Loading";
import { useLoginContext } from "../../contexts/LoginContext";
import { withAppLayout } from "../../parts/AppLayout";
import Form from "./Form";

const Profile = props => {
  const { getAccount } = useLoginContext();
  const account = getAccount();

  if (!account) {
    return <Loading/>;
  }

  if (!account.app_metadata?.identity) {
    return (
      <div className="flex-1 flex flex-col items-center justify-center">
        Sorry, could not load your profile information.
      </div>  
    );
  }

  return (
    <div className="flex-1 flex flex-col items-center justify-center">
      <Form
        block={!account.app_metadata?.identity}

      >

      </Form>
    </div>
  )
};

export default withAppLayout(Profile);
