import React, { useState, useMemo, useEffect } from "react";

//import { withNavs } from "../../queries";

//import Column from "./Column";
//import { useCMSContext } from "../../../plugins/sanity-modular-cms";

const withNavs = c => c;

const Footer = (props) => {
  const {
//    allSanityNav: { edges: navEdges = [] },
  } = props;
  
  //const { lang, pageConfig, site } = useCMSContext();

  // Handles the initial verification regarding Cookie policy accepted and always open
  // SiteLangSelector if the cookie has not been set.
  // useEffect(() => {
  //   if (!accepted) {
  //     const hasAccepted = Cookies.get('gatsby-gdpr-google-tagmanager') === 'true';
  //     setAccepted(hasAccepted);
  //     if (!hasAccepted && !selectLang) {
  //       setSelectLang(true);
  //     }
  //   }
  // }, [selectLang, accepted]);

  return null;

  return (
    <footer className="bg-depalmaBlue text-depalmaWhite px-6">
      <div className="xl:container mx-auto pt-8 grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
        <div className="col-span-2 md:col-span-1 items-center md:items-start flex flex-col">
          <div className="flex-0 h-12">
          
          </div>

          <div className="mb-4 flex gap-4 text-3xl justify-center items-center md:justify-start md:flex-wrap h-6">

          </div>
        </div>

        {/* <Column
          nav={menu1}
          lang={lang}
          site={site}
        />

        <Column
          nav={menu2}
          lang={lang}
          site={site}
        /> */}
      </div>

      <div className="flex-0 mb-4">
        <div className="text-sm flex justify-center flex-col sm:flex-row items-center sm:items-stretch">
          <div>© {new Date().getFullYear()} DePalma Workwear Limited</div>
        </div>
      </div>
    </footer>
  );
};

export default withNavs(Footer);
