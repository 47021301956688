import { navigate } from "gatsby-link";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { FaArrowCircleRight } from "react-icons/fa";
import AsyncSelect from "react-select/async";
import { useGetApi, useIndexApi } from "@fabriqo/api-rest";
import { apiIdForSchema, listPathForSchema } from "../../services/app";

import { FieldWrapper, LabelWrapper } from "./Wrappers";

const labelFromOption = option =>
  option.name || option.title || option.label || option._id || option._ref;
const getOptionValue = option => option._id || option._ref;

const FieldReference = props => {
  const {
    attribute,
    control,
    errors = {},
    field,
    label,
    layout = "vertical",
    options = [],
    placeholder = label,
    watch,
    ...rest
  } = props;

  const { to, type } = field || {};

  // Only do this once on first render
  const [possibleSchemaReferences] = useState(
    (to || []).filter(reference => apiIdForSchema(reference.type))
  );
  const [selectedOptionBlock, setSelectedOptionBlock] = useState(null);
  const apiBySchema = possibleSchemaReferences.reduce(
    (acc, reference) => ({
      ...acc,
      [reference.type]: apiIdForSchema(reference.type),
    }),
    {}
  );
  const get = useGetApi('blocks');
  const index = useIndexApi();
  const value = watch(attribute);

  useEffect(() => {
    (async () => {
      if (
        value?._ref &&
        selectedOptionBlock?._id !== value._ref
      ) {
        const block = await get(value._ref);
        setSelectedOptionBlock(block);
      }
    })();
  }, [value, selectedOptionBlock]);

  const handleChange = selectedOption => {
    setSelectedOptionBlock(selectedOption);
    return selectedOption
      ? { _type: "reference", _ref: selectedOption._id || selectedOption._ref }
      : null;
  };
  const loadRef = useRef(null);
  const loadOptions = useCallback(
    (inputValue, callback) => {
      clearTimeout(loadRef.current);
      if (possibleSchemaReferences.length && inputValue) {
        loadRef.current = setTimeout(async () => {
          try {
            const results = await Promise.all(
              possibleSchemaReferences.map(async reference => {
                const apiId = apiBySchema[reference.type];
                const [response, error] = await index(apiId, {
                  limit: 5,
                  params: { search: inputValue },
                });
                const { records } = response;
                return { data: records };
              })
            );
            callback(
              results.reduce((acc, res) => [...acc, ...(res.data || [])], [])
            );
          } catch (e) {
            console.log(e)
            callback([]);
          }
        }, 500);
      } else {
        callback([]);
      }
    },
    [type, to]
  );

  const getOptionLabel = useCallback(
    option => {
      if (
        option._type === "reference" &&
        selectedOptionBlock?._id === option._ref
      ) {
        return labelFromOption(selectedOptionBlock);
      } else {
        return labelFromOption(option);
      }
    },
    [selectedOptionBlock]
  );

  const renderActions = useCallback(
    () => selectedOptionBlock ? (
      <button
        type="button"
        onClick={() => {
          const listPath = listPathForSchema(selectedOptionBlock._type);
          listPath && navigate(`${listPath}/${selectedOptionBlock._id}`)
        }}
      >
        <FaArrowCircleRight
          title="Navigate to"
          className="text-depalmaBlack"
        />
      </button>
    ) : null,
    [selectedOptionBlock]
  );

  const compProps = {
    defaultOptions: [selectedOptionBlock].filter(Boolean),
    getOptionLabel,
    getOptionValue,
    loadOptions,
    placeholder,
  };

  const renderControl = ({ field, fieldState, formState }) => (
    <AsyncSelect
      {...rest}
      {...field}
      {...compProps}
      onChange={option => field.onChange(handleChange(option))}
      isClearable={true}
      styles={{
        control: (styles, { isFocused }) => ({
          ...styles,
          borderColor: "rgba(160, 174, 192, var(--tw-border-opacity))",
          borderRadius: "none",
          boxShadow: isFocused
            ? "0 0 0 3px rgba(66, 153, 225, 0.5)"
            : "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
          outline: "none",
          "&:hover": {},
        }),
      }}
    />
  );

  return (
    <LabelWrapper label={label} layout={layout}>
      <FieldWrapper layout={layout} renderActions={renderActions}>
        <Controller
          {...rest}
          {...compProps}
          control={control}
          name={attribute}
          selectedOptionBlock={selectedOptionBlock}
          render={renderControl}
        />
      </FieldWrapper>
    </LabelWrapper>
  );
};

export default FieldReference;
