import React from "react";
import { FaCog } from "react-icons/fa";

const Loading = props => {

  return (
    <div className="flex-1 flex flex-col min-h-full justify-center">
      <FaCog className=" text-6xl animate-spin mx-auto" />
    </div>
  );
};

export default Loading;