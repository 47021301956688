import React, { Fragment, useMemo } from "react";
import { useForm } from "react-hook-form";

import { renderField } from "../../components/Forms";
import Modal from "../../components/Modal";

const AddDialogue = props => {
  const {
    defaultValues = null,
    index,
    onClose,
    onSubmit,
    title = 'Add'
  } = props;

  const { fields } = index;
  const { control, handleSubmit, register, formState, reset, watch } = useForm({ mode: 'onChange', defaultValues });

  const addFields = useMemo(() => {
    return fields.filter(field => field.type === 'string');
  }, [fields]);

  return (
    <Modal
      confirmLabel={'Add'}
      hasConfirm={true}
      hasClose={true}
      hideHeader={false}
      onClose={onClose}
      onConfirm={handleSubmit(onSubmit)}
      title={title}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {addFields.map((field, i) => (
          <Fragment key={i}>
            {renderField({ field, control, formState, record: defaultValues || {}, register, watch })}
          </Fragment>
        ))}
      </form>
    </Modal>
  );
}

export default AddDialogue;
