import React, { useCallback, useEffect, useState } from "react";
import { navigate } from "gatsby-link";
import { FaUserAlt } from "react-icons/fa";
import { useApi, useListApi } from "@fabriqo/api-rest";
import {Table} from "@fabriqo/components";

import { ContentLayout } from "../CRM";
import LinkCellNavigate from "../DocumentList/LinkCellNavigate";

import Actions from "./Actions";
import AddDialogue from "./AddDialogue";

const MANDATORY_FIELDS = [
  { name: 'name', title: 'Name', type: 'string' },
];

const tableComponents = {
  LinkCell: LinkCellNavigate,
};

const LocationsList = props => {
  const {
    apiId = 'marketing_profile',
    baseLinkURL = '/app/crm/marketing_profiles',
  } = props;

  const [addNew, setAddNew] = useState(null);

  const index = useListApi(apiId);
  const { create } = useApi(apiId);

  const { noOfRecords, load } = index;
  useEffect(() => { noOfRecords === 0 && load() }, []);

  const handleSubmitAdd = useCallback(async data => {
    try {
      const block = await create(data);
      setAddNew(null);
      navigate(`${baseLinkURL}/${block._id}`);
    } catch(e) {
      console.log(e);
    }
  }, []);

  return (
    <>
      <ContentLayout>
        <div className="flex items-start justify-between">
          <h1 className="text-lg mb-2">
            <FaUserAlt className="mr-2 inline" />
            Marketing Profiles
          </h1>
        </div>

        <Actions
          index={index}
          onClickAdd={() => setAddNew(true)}
        />

        <Table
          components={tableComponents}
          index={index}
          baseLinkURL={baseLinkURL}
        />

        {addNew ? (
          <AddDialogue
            fields={MANDATORY_FIELDS}
            onClose={() => setAddNew(null)}
            onSubmit={handleSubmitAdd}
            title="Add Marketing Profile"
          />
        ) : null}
      </ContentLayout>
    </>
  );
};

export default LocationsList;
