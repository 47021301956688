import React from "react";
import clsx from "clsx";

import { displayErrors } from "./errors";
import { FieldWrapper, LabelWrapper } from "./Wrappers";

const FieldText = props => {
  const {
    attribute,
//    defaultValue,
    description = null,
    errors = {},
    isDirty = false,
    label,
    layout = 'vertical',
    placeholder = label,
    register,
    watch,
    ...rest
  } = props;

  return (
    <LabelWrapper label={label} layout={layout}>
      <FieldWrapper layout={layout}>
        <input
          {...rest}
          {...register(attribute)}
          className={clsx(
            "block shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline",
            { "border-red-500": !!errors[attribute] },
            { "border-gray-500": !errors[attribute] },
            { "bg-orange-200": isDirty },
            { "flex-1": layout === 'horizontal' }
          )}
          placeholder={placeholder}
          type="text"
        />
      </FieldWrapper>
    </LabelWrapper>
  );
};

export default FieldText;
