import { Device } from "@twilio/voice-sdk";
import axios from "axios";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useLoginContext } from "./LoginContext";


const defaultState = {
  device: undefined,
  activeCall: undefined,
};

const TwilioStateContext = createContext(null);

const TwilioProvider = props => {
  const { children } = props;
  const [status, setStatus] = useState('disconnected');
  const [callStatus, setCallStatus] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [state, setState] = useState(defaultState);
  const [log, setLog] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const { getToken } = useLoginContext();
  const { device } = state;

  const logMsg = useCallback(msg => setLog(l => [...l, [new Date(), msg]]), []);

  const connect = useCallback(async () => {
    if (device) {
      return device;
    }
  
    try {
      logMsg('Requesting access');
      setStatus('connecting');
      const { data } = await axios.post('/.netlify/functions/twilioGenerate', null, { headers: { 'Authorization': `Bearer ${getToken()}` } });
      logMsg('Access granted');
  
      const device = new Device(data.accessToken);
      device.on("ready", device => logMsg("Twilio.Device Ready!"));
      device.on("error", error => logMsg("Twilio.Device Error: " + error.message));
      device.on("connect", conn => logMsg('Successfully established call ! '));
      device.on("disconnect", conn => logMsg("Call ended."));
  
      setState(s => ({ ...s, device }));
      setStatus('connected');
  
      return device;
    } catch(e) {
      logMsg(e.message);
      return null;
    }
  }, [getToken, device]);

  const dial = useCallback(async params => {
    try {
      const connection = await device.connect({ params });
      connection.on("closed", () => logMsg("Closed..."));
      connection.on("cancel", () => logMsg("Cancel..."));
      connection.on("connecting", () => logMsg("Connecting..."));
      connection.on("disconnect", () => logMsg("Disconnected..."));
      connection.on("open", () => logMsg("Open..."));
      connection.on("pending", () => logMsg("Pending..."));
      connection.on("reconnecting", () => logMsg("Reconnecting..."));
      connection.on("reject", () => logMsg("Reject..."));
      connection.on("ringing", () => logMsg("Ringing..."));
    } catch(e) {
      logMsg(e.message);
    }
  }, [device]);

  const endCall = useCallback(() => {
    if (device) {
      device.disconnectAll();
    }
  }, [device]);

  const context = {
    ...state,
    callStatus,
    connect,
    dial,
    endCall,
    isOpen,
    log,
    phoneNumber,
    setIsOpen,
    setPhoneNumber,
    status,
  };

  return (
    <TwilioStateContext.Provider value={context}>
      {children}
    </TwilioStateContext.Provider>
  );
};

export const useTwilio = () => {
  const context = useContext(TwilioStateContext);

  if (context === undefined) {
    throw new Error('useTwilio must be used within a TwilioProvider');
  }

  return context;
};

export default TwilioProvider;
