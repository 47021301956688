import React, { useCallback, useEffect, useRef, useState } from "react";

import { useApi } from "@fabriqo/api-rest";
import Loading from "../../components/Loading";

import Note from "./Note";
import NoteForm from "./NoteForm";

const Notes = props => {
  const { active = false, block } = props;
  const [loaded, setLoaded] = useState(false);
  const [notes, setNotes] = useState([]);

  const resetNewNoteFormRef = useRef(null);

  const { create, destroy, get, getSchema, getFields, index, update } = useApi("note");
  const handleCreate = useCallback(
    async data => {
      try {
        const newNote = await create({
          ...data,
          target: { _type: "reference", _ref: block._id },
        });
        setNotes(notes => [...notes, newNote]);
        resetNewNoteFormRef.current && resetNewNoteFormRef.current();
      } catch (e) {
        console.log(e);
      }
    },
    [create]
  );

  useEffect(() => {
    (async () => {
      if (active && !loaded) {
        const [data, error] = await index({
          params: { query: { target: { _ref: { $eq: block._id } } } },
        });
        if (!error) {
          const { records, noOfRecords } = data;
          setNotes(records);
        }
        setLoaded(true);
      }
    })();
  }, [active, loaded, block]);

  const handleDestroy = useCallback(note => {
    setNotes(notes => notes.filter(n => n._id !== note._id));
  }, []);

  const handleUpdate = useCallback(note => {
    setNotes(notes => notes.map(n => (n._id === note._id ? note : n)));
  }, []);

  const renderNote = useCallback((note, i) => {
    return (
      <Note
        key={i}
        note={note}
        onDestroy={handleDestroy}
        onUpdate={handleUpdate}
      />
    );
  }, []);

  return (
    <div className="flex-1 flex flex-col h-full">
      {loaded ? (
        <>
          <div className="flex-1">
            <div className="h-full overflow-scroll">
              {notes.length ? (
                notes.map(renderNote)
              ) : (
                <p className="text-sm italic">
                  There are no notes for this record.
                </p>
              )}
            </div>
          </div>

          <div className="flex-initial">
            <h3 className="uppercase text-sm font-bold">Add new Note</h3>
            <NoteForm
              onSubmit={handleCreate}
              resetFormRef={resetNewNoteFormRef}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Notes;
